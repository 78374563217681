import { getRealSize } from "./getRealSize";
export const makeDemo =
	/**
	 *
	 * @param {Blob} blob
	 * @param {HTMLImageElement} img
	 * @param {HTMLElement} text
	 * @return {void}
	 */
	(blob, img, text) => {


	const url = URL.createObjectURL(blob);

	img.onload = function () {
		text.innerText = `size is ${img.naturalWidth} / ${img.naturalHeight}`;
		URL.revokeObjectURL(url);
	}
	img.src = url;
}
