export const params = {
	// readyImgWidth: 2520, // now 5040 || was 2016
	// readyImgHeight: 1004 * 1.49054, // now 2993 || was 803.2 * 1.2
	// userImgSizeH: 1004, // img height
	// userImgSizeW: 1004 * .83, // img width (img height * aspect-ratio)
	// imgGap: 1004 * 0.245, // was 803.2 * 0.1
	readyImgWidth: 1999, // now 5040 || was 2016
	readyImgHeight: 796 * 1.49054, // now 2993 || was 803.2 * 1.2
	userImgSizeH: 796, // img height
	userImgSizeW: 796 * .83, // img width (img height * aspect-ratio)
	imgGap: 796 * 0.245, // was 803.2 * 0.1
	blur: 0, //0-15
	brightness: 85, // 0-300
	contrast: 150, // 0-500
	dropShadowX: 0, //0-30
	dropShadowY: 0, //0-30
	dropShadowSpread: 85, //0-200
	dropShadowColor: {
		r: .93,
		g: 0.89,
		b: 0.64,
	},

	// dropShadowcolorAlpha: 0.65, // 0-1
	dropShadowcolorAlpha: 0, // 0-1
	grayscale: 0, //0-100
	hueRotate: 0, //0-180
	invert: 0, //0-100
	opacity: 90, //0-100
	saturate: 0, //0-100
	sepia: 30, //0-100

	mask: 'softedge',
	sets: [{
			name: 'melk',
			color: "#d9001b",
			// img: "../img/print-files/melk.jpg"
			img: "../img/print-files/melk_big.jpg"
		},
		{
			name: 'noir',
			color: "#85754d",
			// img: "../img/print-files/noir.jpg"
			img: "../img/print-files/noir_big.jpg"
		},
		{
			name: 'puur',
			color: "#3f2120",
			// img: "../img/print-files/puur.jpg"
			img: "../img/print-files/puur_big.jpg"
		},
		{
			name: 'karamel',
			color: "#e36021",
			// img: "../img/print-files/karamel.jpg"
			img: "../img/print-files/karamel_big.jpg"
		},
		{
			name: 'nootjes',
			color: "#3bac2d",
			// img: "../img/print-files/nootjes.jpg"
			img: "../img/print-files/nootjes_big.jpg"
		},
	]
}

