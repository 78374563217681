import axios from 'axios';
import { isLocal } from './isLocal';
import { imgToBlob } from './imgToBlob';

const uploadFile = async (file) => {
	const   formD = new FormData(),
			formSubmitUrlFile = '/@/backend/file/upload',
			pathStorageUrl = '/@/file-storage/';

	// const res = await imgToBlob(data.selectedImg, 'thumbnail');

	formD.append("file", file);

	try {
		const response = await axios({
			method: "POST",
			url: formSubmitUrlFile,
			data: formD,
			headers: {
				'Content-Type': `multipart/form-data; boundary=${formD._boundary}`,
			},
		});


		return {
			"id": response.data.id,
			"path": pathStorageUrl + response.data.path
		}

	} catch (err) {
		console.log(err.message);

	} finally {

	}

}



export const formHandler = async (data) => {


	const [thumbnailBlob,fullBlob] = await Promise.all([imgToBlob(data.selectedImg, 'imgUserThumbnail'),imgToBlob(data.selectedImg, 'imgUserOriginal')])
	const [thumbData, imgData] = await Promise.all([uploadFile(thumbnailBlob),uploadFile(fullBlob)])


	const response = await axios({
		method: "POST",
		url: '/@/backend/card/new',
		data: {
			"lang": document.documentElement.lang,
			"imageId": imgData.id,
			"thumbnailId": thumbData.id,
			"year": String(data.year),
  			"name": data.name
		}
	});

	const readyData = response.data;
	// console.log(readyData);

	return {code: readyData.code, src: '/@/file-storage/' + readyData.thumbnail.path}

}
