import imgHandler from './imgHandler'


export const loadImg = function() {
    const   userImgLoad = document.getElementById('userImg'),
            loadImgWrapper =  document.getElementById('loadImg'),
            name = loadImgWrapper.querySelector('.load-img__name'),
            remove = loadImgWrapper.querySelector('.load-img__remove'),
            imgToCrop = document.getElementById('cropImg'),
            btnToSecondStep = document.getElementById('toSecondStep'),
            cropper = document.getElementById('cropper');

    // helper
    function removeExtension(filename) {
        return filename.substring(0, filename.lastIndexOf('.')) || filename;
    }


    function loadAndReadyImg(event){
        if (event.target.files.length > 0) {
            const   src = URL.createObjectURL(event.target.files[0]),
                    fileName = removeExtension(event.target.files[0].name);


            if(event.target.files[0].size >= 10000000){
                loadImgWrapper.classList.remove('is-empty');
                loadImgWrapper.classList.remove('is-active');
                loadImgWrapper.classList.add('is-error');

                cropper.classList.remove('is-active');
                return false;
            }

            // console.log(name);
            imgToCrop.src = src;
            name.innerText = fileName;
            loadImgWrapper.classList.remove('is-empty');
            loadImgWrapper.classList.remove('is-error');
            loadImgWrapper.classList.add('is-active');
            cropper.classList.add('is-active');
            imgHandler.init()

        } else {
            imgToCrop.src = '';
            loadImgWrapper.classList.add('is-empty');
            loadImgWrapper.classList.remove('is-error');
            loadImgWrapper.classList.remove('is-active');
            cropper.classList.remove('is-active');
            imgHandler.stop()
        }
    }

    userImgLoad.addEventListener('change',loadAndReadyImg);


    remove.addEventListener('click',()=>{
        imgToCrop.src = '';
        loadImgWrapper.classList.add('is-empty');
        loadImgWrapper.classList.remove('is-error');
        loadImgWrapper.classList.remove('is-active');
        cropper.classList.remove('is-active');
        btnToSecondStep.disabled = true;
        imgHandler.stop()
    })

}
