import './lib/poly';
import { params } from './components/params';
import {
	loadImg
} from "./components/loadImg";
import {
	prepareUserImg
} from "./components/prepareUserImg";
import {
	formHandler
} from "./components/formHandler";
import {
	isLocal
} from "./components/isLocal";

import './components/kiosk';


import axios from "axios";


import Swiper, {
	Navigation,
	Lazy,
	Pagination,
	EffectFade,
	Autoplay
} from "swiper";
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);


const app = document.getElementById('mainFlow');




if (!!app) {



	// switcher beside inner 'pages'
	window.swiperPage = new Swiper("#mainFlowSlider", {
		direction: "horizontal",
		slidesPerView: 1,
		effect: "fade",
		centeredSlides: false,
		loop: false,
		autoHeight: true,

		// temp
		// initialSlide: 1,

		fadeEffect: {
			crossFade: true
		},
	});

	// make load img great again
	loadImg();


	window.dataSend = {
		thumbnail: '',
		selectedImg: '',
		year: '',
		name: ''
	}

	// form inputs checker
	const year = document.getElementById('year'),
		name = document.getElementById('name'),
		img = document.getElementById('userImg'),
		agreement = document.getElementById('agreement'),
		btnToSecondStep = document.getElementById('toSecondStep');

	// page submited
	const 	code = document.getElementById('imgCode'),
			selectedImg = document.getElementById('selectedImg');

	function checkStep1Ready() {
		let goals = 0;
		if (name.value.trim() !== '') {
			goals++;
		} else {
			goals = 0;
		}
		if (year.value !== '' && year.value.length === 4) {
			goals++;
		} else {
			goals = 0;
		}

		if (img.value !== '') {
			goals++;
		} else {
			goals = 0;
		}
		if ( agreement.checked){
			goals++;
		} else {
			goals = 0;
		}

		if (goals === 4) {
			btnToSecondStep.disabled = false;
		} else {
			btnToSecondStep.disabled = true;
		}
	}

	year.addEventListener('keydown', () => {
		if (year.value.length > 3) {
			year.value = year.value.slice(0, 3);
		}
	})

	name.addEventListener('input', checkStep1Ready)
	year.addEventListener('input', checkStep1Ready)
	agreement.addEventListener('change', checkStep1Ready)
	img.addEventListener('input', checkStep1Ready)



	// if all ok, then we throw to next step
	btnToSecondStep.addEventListener('click', async () => {
		// const params = await outerParams();

		if (await prepareUserImg()) {
			// swiperPage.slideNext();
			dataSend.selectedImg = document.getElementById('readyUserImg');
			document.getElementById('step1').dataset.sending++;
			dataSend.name = name.value;
			dataSend.year = year.value;
			const data = await formHandler(dataSend);
			if (!!data.code && !!data.src) {
				// console.log(data);
				code.innerHTML = data.code;
				selectedImg.src = data.src;
				selectedImg.onload = () => {
					swiperPage.slideNext();
				}
			}
		}

		// 		dataSend.selectedImg = e.target;
		// 		const closest = e.target.closest('.pack__item');
		// 		closest.classList.add('is-sending');
		// 		const others = document.querySelectorAll('.pack__item:not(.is-sending)');
		// 		others.forEach((item)=>{
		// 			item.classList.add('is-invisible')
		// 		})
		// 		setTimeout(() => {
		// 			closest.classList.add('is-sending_animate')
		// 		}, 100);
		// 		const data = await formHandler(dataSend);
		// 		if (!!data.code && !!data.src) {
		// 			console.log(data);
		// 			code.innerHTML = data.code;
		// 			selectedImg.src = data.src;
		// 			selectedImg.onload = () => {
		// 				swiperPage.slideNext();
		// 			}
		// 		}
		// 	}
		// })
	})


}
