import {
	imgToBlob
} from "./imgToBlob";
import axios from 'axios';
import { params } from './params';
import { imgsBundler } from "./imgsBundler";


const kioskPageImgs = document.querySelector('.kiosk-page_imgs');
const kioskPage = document.querySelector('.kiosk-page_print');



//set imgs to list
// function setImgs(data, clear){
// 	const 	list = document.getElementById('operatorList'),
// 			placeholderTime = document.getElementById('operatorListPlaceholderTime'),
// 			placeholderCode = document.getElementById('operatorListPlaceholderCode');
// 	if(data.results.length > 0){
// 		placeholderCode.classList.add('hidden')
// 		placeholderTime.classList.add('hidden')
// 		if(clear){
// 			list.innerHTML = ''
// 		}
// 		for (let i = 0; i < data.results.length; i++) {
// 			const item = data.results[i];

// 			// console.log(item);
// 			// console.log(`/@/file-storage/${item.thumbnail.path}`);
// 			// console.log(`/@/file-storage/${item.image.path}`);
// 			const tpl = document.createElement('template');
// 			tpl.innerHTML = `
// 			<li>
// 				<div class="pack__item-image-wrapper">
// 					<img class="pack__item-image js-scalable" id="${item.code}" src="/@/file-storage/${item.thumbnail.path}" data-mfp-src="/@/file-storage/${item.image.path}">
// 				</div>
// 			</li>
// 			`;
// 			// console.log(list);
// 			list.appendChild(tpl.content)
// 		}

// 		jQuery(function ($) {
// 			$('.js-scalable').magnificPopup({
// 				type: 'image',
// 				mainClass: 'mfp-with-zoom',
// 				zoom: {
// 					enabled: true,
// 					duration: 300,
// 					easing: 'ease-in-out',
// 					opener: function (openerElement) {
// 						// openerElement is the element on which popup was initialized, in this case its <a> tag
// 						// you don't need to add "opener" option if this code matches your needs, it's defailt one.
// 						return openerElement.is('img') ? openerElement :
// 							openerElement.find('img');
// 					}
// 				},
// 				image: {
// 					markup: '<div class="mfp-figure kiosk-modal">' +
// 						'<div class="mfp-close"></div>' +
// 						'<div class="mfp-img__wrapper">' +
// 						'<div class="mfp-img"></div>' +
// 						'</div>' +
// 						'<div class="kiosk-modal__btns">' +
// 						'<button id="operatorModalClose" class="print__btn btn --gold"><div class="btn__wrap"><img class="btn__icon" src="/img/close.svg"><span>Close</span></div></button>' +
// 						'<button id="operatorPrint" class="print__btn btn --primary"><div class="btn__wrap"><img class="btn__icon" src="/img/printer.svg"><span>Print</span></div></button>' +
// 						'</div>' +
// 						'<div class="mfp-bottom-bar">' +
// 						'<div class="mfp-title"></div>' +
// 						'<div class="mfp-counter"></div>' +
// 						'</div>' +
// 						'</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

// 					cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

// 					titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
// 					// Or the function that should return the title. For example:
// 					// titleSrc: function(item) {
// 					//   return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
// 					// }

// 					verticalFit: true, // Fits image in area vertically

// 					tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
// 				}
// 			});

// 		});

// 	} else {
// 		if(clear){
// 			list.innerHTML = ''
// 		}

// 		if(clear){
// 			placeholderCode.classList.remove('hidden')
// 			placeholderTime.classList.add('hidden')
// 		} else {
// 			placeholderTime.classList.remove('hidden')
// 			placeholderCode.classList.add('hidden')
// 		}

// 	}

// }
function setImgs(data, clear) {
	const list = document.getElementById('operatorList'),
		placeholderTime = document.getElementById('operatorListPlaceholderTime'),
		placeholderCode = document.getElementById('operatorListPlaceholderCode');
	if (data.results.length > 0) {
		placeholderCode.classList.add('hidden')
		placeholderTime.classList.add('hidden')
		if (clear) {
			list.innerHTML = ''
		}
		for (let i = 0; i < data.results.length; i++) {
			const item = data.results[i];

			// console.log(item);
			// console.log(`/@/file-storage/${item.thumbnail.path}`);
			// console.log(`/@/file-storage/${item.image.path}`);
			const tpl = document.createElement('template');
			tpl.innerHTML = `
			<li class="operator-list__item"
			data-img="/@/file-storage/${item.imageWithoutBackground.path}"
			data-name="${item.name}"
			data-year="${item.year}"
			data-lang="${item.lang}"
			>
				<img id="${item.code}" src="/@/file-storage/${item.thumbnail.path}">
				<div class="crop-sending-window">
					<div class="crop-sending-window__text">
						<p>Sending the form...</p>
					</div>
					<div class="crop-sending-window__status">
						<div class="inner"></div>
					</div>
				</div>
			</li>
			`;
			// console.log(list);
			list.appendChild(tpl.content)
		}

	} else {
		if (clear) {
			list.innerHTML = ''
		}

		if (clear) {
			placeholderCode.classList.remove('hidden')
			placeholderTime.classList.add('hidden')
		} else {
			placeholderTime.classList.remove('hidden')
			placeholderCode.classList.add('hidden')
		}

	}

}

//send img to print
const toPrint = async (img) => {
	const image = await imgToBlob(img);
	const formData = new FormData();
	formData.append('image', image);

	try {
		const response = await fetch(
			`/${document.documentElement.lang}/print`, {
				method: 'POST',
				body: formData,
			}
		);

		if (!response.ok) {
			alert('Something went wrong, please contact technical support.');
		} else {
			//   location.href = '/';
			alert('Sent to printer')
		}

	} catch {
		alert('Something went wrong, please contact technical support.');
	}
}

// get items
let previousData =
	'[{"id":"373e6d1c-70fb-4e86-9f09-a8f21972de75","lang":"nl","code":"6538","createdAt":"2023-01-12T10:52:00.453Z","updatedAt":"2023-01-12T10:52:00.454Z","image":{"id":"5d3146a6-c01a-4f6d-8464-18e039e35dc8","path":"3c89/69a5/111f/b3bc/181f/d6ab/cd44/b675/a628/5383/9419/73d0/316f/a8cb/38e5/2b08.png"},"thumbnail":{"id":"5043669f-626e-4e77-9da6-91cd5dc5b3db","path":"1cc2/d438/542c/9994/9055/66f0/3fb9/adfa/8379/780f/4639/fb71/0c5b/ab78/5d97/5ccc.png"}}]';
const getItems =
	/**
	 *
	 * @param {'asc' | 'desc'} sortDirection
	 * @param {number} page
	 * @param {number} perPage
	 * @param {"created_at" | "code"} sort
	 * @param {number} search
	 * @param {number} lifetime
	 * @param {boolean} clear
	 * @returns {Promise<void>}
	 */
	async ({
		sortDirection = 'asc',
		page = '1',
		perPage = "50",
		sort = "created_at",
		search = "",
		lifetime = "",
		clear = false
	}) => {


		const response = await axios({
			method: "GET",
			url: `/@/backend/card/list?${search !== "" ? 'search='+search+'&' : ''}sort_direction=${sortDirection}&page=${page}&per_page=${perPage}&sort=${sort}${lifetime !== "15" ? '&lifetime='+lifetime : ''}`,
		});

		if (JSON.stringify(previousData?.results) !== JSON.stringify(response.data.results)) {
			previousData = response.data;
			setImgs(response.data, clear)
		}

	}

function clearLocal(){
	// console.log('dsfd');
	params.sets.forEach((set)=>{
		localStorage.removeItem(set.name+'_big')
		localStorage.removeItem(set.name+'_small')
	})
}




if (!!kioskPageImgs) {
	$(function () {
		let lt = 15;
		if (window.location.search !== '') {
			const params = new URLSearchParams(window.location.search);
			lt = params.get('lifetime');
		}
		getItems({
			lifetime: lt
		})
		clearLocal()



		setInterval(() => {
			if (document.getElementById('code') !== document.activeElement) {
				getItems({
					lifetime: lt,
					clear: true
				})
			}
		}, 10000);



		jQuery(function ($) {
			$(document).on('click', '#operatorList .operator-list__item',
			async function (el) {
					if (!!this.dataset.img && !!this.dataset.lang && !!this.dataset.name && !!this.dataset.year) {

						this.classList.add('is-sending')
						setTimeout(() => {
							this.classList.add('is-sending_animate')

						}, 100);

						const user = {
							name: this.dataset.name,
							year: this.dataset.year,
							lang: this.dataset.lang,
							img: this.dataset.img
						}
						if(await imgsBundler(user)){
							setTimeout(() => {

								$('#kiosk-userImgs').hide(100);
								$('#kiosk-readiImgs').show(200);
								$('.js-scalable').magnificPopup({
									type: 'image',
									mainClass: 'mfp-with-zoom',
									zoom: {
										enabled: true,
										duration: 300,
										easing: 'ease-in-out',
										opener: function (openerElement) {
											// openerElement is the element on which popup was initialized, in this case its <a> tag
											// you don't need to add "opener" option if this code matches your needs, it's defailt one.
											return openerElement.is('img') ? openerElement :
												openerElement.find('img');
										}
									},
									image: {
										markup: '<div class="mfp-figure kiosk-modal">' +
											'<div class="mfp-close"></div>' +
											'<div class="mfp-img__wrapper">' +
											'<div class="mfp-img"></div>' +
											'</div>' +
											'<div class="kiosk-modal__btns">' +
											'<button id="operatorModalClose" class="print__btn btn --gold"><div class="btn__wrap"><img class="btn__icon" src="/img/close.svg"><span>Close</span></div></button>' +
											'<button id="operatorPrint" class="print__btn btn --primary"><div class="btn__wrap"><img class="btn__icon" src="/img/printer.svg"><span>Print</span></div></button>' +
											'</div>' +
											'<div class="mfp-bottom-bar">' +
											'<div class="mfp-title"></div>' +
											'<div class="mfp-counter"></div>' +
											'</div>' +
											'</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

										cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

										titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
										// Or the function that should return the title. For example:
										// titleSrc: function(item) {
										//   return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
										// }

										verticalFit: true, // Fits image in area vertically

										tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
									}
								});
							}, 1000);
						}
					}

				});
			$(document).on('click', '#operatorModalClose', function () {
				$.magnificPopup.close()
			});
			$(document).on('input', '#code', function () {
				if (this.value.length > 1) {
					getItems({
						lifetime: 99999,
						search: this.value,
						clear: true
					})
				} else {
					$('#operatorList').html('')
				}
			});


			$(document).on('click', '#operatorPrint', function () {
				const img = $(this).closest('.kiosk-modal').find(
				'.mfp-img');
				// console.log(img);
				// console.log(img[0].src);
				toPrint(img[0])
			})
		});
	});
}

const fillList = async (set) =>{
	const 	target = document.getElementById(set.name),
			img = localStorage.getItem(`${set.name}_big`),
			thumbnail = localStorage.getItem(`${set.name}_small`);

	if (!!target && !!img && !!thumbnail) {
		target.src = thumbnail;
		target.dataset.mfp = img;
	}
}




if (!!kioskPage) {
	jQuery(function ($) {
		const sets = params.sets;

		sets.forEach(set=>{
			// console.log(set);
			fillList(set)
		})

		// popup
		$('.js-scalable').magnificPopup({
			type: 'image',
			mainClass: 'mfp-with-zoom',
			zoom: {
				enabled: true,
				duration: 300,
				easing: 'ease-in-out',
				opener: function (openerElement) {
					// openerElement is the element on which popup was initialized, in this case its <a> tag
					// you don't need to add "opener" option if this code matches your needs, it's defailt one.
					return openerElement.is('img') ? openerElement :
						openerElement.find('img');
				}
			},
			image: {
				markup: '<div class="mfp-figure kiosk-modal">' +
					'<div class="mfp-close"></div>' +
					'<div class="mfp-img__wrapper">' +
					'<div class="mfp-img"></div>' +
					'</div>' +
					'<div class="kiosk-modal__btns">' +
					'<button id="operatorModalClose" class="print__btn btn --gold"><div class="btn__wrap"><img class="btn__icon" src="/img/close.svg"><span>Close</span></div></button>' +
					'<button id="operatorPrint" class="print__btn btn --primary"><div class="btn__wrap"><img class="btn__icon" src="/img/printer.svg"><span>Print</span></div></button>' +
					'</div>' +
					'<div class="mfp-bottom-bar">' +
					'<div class="mfp-title"></div>' +
					'<div class="mfp-counter"></div>' +
					'</div>' +
					'</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

				cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

				titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
				// Or the function that should return the title. For example:
				// titleSrc: function(item) {
				//   return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
				// }

				verticalFit: true, // Fits image in area vertically

				tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
			}
		});


	});
}
