import {
	isLocal
} from "./isLocal";
import {
	getRealSize
} from "./getRealSize";
import { params } from './params';

export const imgsBundler =
	/**
	 * @param {{ readonly name: string; readonly year: number; readonly lang: 'nl' | 'fr'; readonly img: string}} user
	 * @return {Promise<{drawedImageWithBox: Blob;}>}
	 */
	// async (outerParams) => {
	async  (user) => {
		const sets = params.sets;


		// add to img filters
		const filter =
			/**
			 *
			 * @param {canvas} bmp
			 * @param {Object<string || number>} filters
			 * @returns {canvas}
			 */
			(bmp, filters = "") => {
				let canvas = Object.assign(document.createElement('canvas'), {
					width: bmp.width,
					height: bmp.height
				});
				let ctx = canvas.getContext('2d');
				ctx.filter = filters;
				ctx.drawImage(bmp, 0, 0);
				return canvas;
			}

		// mix img with mask
		const mask =
			/**
			 *
			 * @param {canvas} img
			 * @param {string} mask
			 * @returns {Promise<{image: Blob;}>}
			 */
			async (img, mask) => {
				let canvas = Object.assign(document.createElement('canvas'), {
					width: img.width,
					height: img.height
				});
				let ctx = canvas.getContext('2d');

				console.log(mask);

				if (mask !== 'none') {
					ctx.drawImage(img, 0, 0);
					ctx.globalCompositeOperation = "destination-out";


					const maskImg = await new Promise((resolve) => {
						const imgToDraw = new Image();

						imgToDraw.onload = function () {
							resolve(imgToDraw)
						}
						imgToDraw.src = `../img/masks/${mask}.png`;

					});

					ctx.drawImage(maskImg, 0, 0, canvas.width, canvas.height);

				} else {
					console.log('no mask');
					ctx.drawImage(img, 0, 0);
				}

				const image = await new Promise((resolve) => {
					canvas.toBlob((result) => {
						resolve(result);
					});
				});

				// return canvas
				return image;

			}

		// merge two canvas into one to generate sketch like image
		const generateSketch =
			/**
			 *
			 * @param {canvas} bnw
			 * @param {canvas} blur
			 * @returns {canvas}
			 */
			(bnw, blur) => {
				let canvas = document.createElement('canvas');
				canvas.width = bnw.width;
				canvas.height = bnw.height;
				canvas.__skipFilterPatch = true; // add this for safari ios
				let ctx = canvas.getContext('2d');
				ctx.drawImage(bnw, 0, 0, canvas.width, canvas.height);
				ctx.globalCompositeOperation = 'color-dodge';
				ctx.drawImage(blur, 0, 0, canvas.width, canvas.height);
				return canvas;
			}

		// add to ready canvas bg (when used 'noir' type)
		const drawCanvasWithBg =
			/**
			 *
			 * @param {canvas} canvas
			 * @returns {Promise<canvas>}
			 */
			async (canvasSrc) => {
				const canvas = document.createElement('canvas'),
					ctx = canvas.getContext('2d');

				canvas.width = params.readyImgWidth;
				canvas.height = params.readyImgHeight;
				canvas.__skipFilterPatch = true; // add this for safari ios

				ctx.save();
				ctx.fillStyle = '#85754d';
				ctx.rect(0, 0, canvas.width, canvas.height);
				ctx.fill();
				ctx.drawImage(canvasSrc, 0, 0, canvas.width, canvas.height);
				ctx.restore();


				return await Promise.resolve(canvas);
			}


		// merge img with bg
		const mix =
			/**
			 *
			 * @param {Blob} img
			 * @param {Object} set
			 * @param {string} addFilter
			 * @returns {Promise<canvas>}
			 */
			async (img, set, addFilter) => {
				const canvas = document.createElement('canvas'),
					ctx = canvas.getContext('2d'),
					logoSrc = user.lang === 'nl' ? '../img/print-files/Logo_CDO140YEARS.png' :
					'../img/print-files/Logo_CDO140ANS.png',
					logoW = 0.2235 * params.readyImgWidth,
					logoH = 0.86 * logoW,
					logoL = 0.056 * params.readyImgWidth;


				canvas.width = params.readyImgWidth;
				canvas.height = params.readyImgHeight;
				canvas.__skipFilterPatch = true; // add this for safari ios

				console.log('test start');

				const newImg = await createImageBitmap(img);

				const bg = await new Promise((resolve) => {
					const imgToDraw = new Image();
					imgToDraw.onload = function () {
						resolve(imgToDraw)
					}
					imgToDraw.src = set.img;

				});
				const logo = await new Promise((resolve) => {
					const imgToDraw = new Image();
					imgToDraw.onload = function () {
						resolve(imgToDraw)
					}
					imgToDraw.src = logoSrc;

				});

				ctx.save();

				ctx.globalCompositeOperation = 'multiply';
				ctx.drawImage(bg, 0, 0, canvas.width, canvas.height);
				ctx.restore();

				ctx.save();
				if (set.name !== 'noir') {
					ctx.filter = addFilter;
				}
				ctx.globalCompositeOperation = set.name === 'noir' ?
					'xor' : 'multiply';
				// ctx.drawImage(newImg, canvas.width - params.userImgSize, 0, params
				// 	.userImgSize, canvas.height);
				ctx.drawImage(newImg, canvas.width - params.userImgSizeW, params.imgGap,
					params
					.userImgSizeW, canvas.height - (params.imgGap * 2));


				ctx.restore();
				if (set.name === 'noir') {
					ctx.save();
					ctx.filter = addFilter;
					ctx.globalCompositeOperation = 'multiply';
					ctx.drawImage(newImg, canvas.width - params.userImgSizeW, params.imgGap,
						params
						.userImgSizeW, canvas.height - (params.imgGap * 2));
					// ctx.drawImage(newImg, canvas.width - params.userImgSize - params.imgYOffset, params.imgY, params.userImgSize, params.userImgSizeHeight);
					ctx.restore();

				}

				ctx.drawImage(logo, logoL, params.imgGap, logoW, logoH);

				return canvas;


			}

		// draw text of year and name
		const drawText = async (canvas, set) => {
			const ctx = canvas.getContext('2d'),
				year = user.year,
				nameLabel = user.lang === 'nl' ? 'Geboorte van' : 'Naissance de',
				name = user.name,
				fontSizeYear = params.readyImgWidth / 6.41,
				textWidth = 0.2235 * params.readyImgWidth;

			let fontSizeName = params.readyImgWidth / 35;


			ctx.fillStyle = set.color;
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';

			// draw name
			ctx.save();

			ctx.font = `700 ${fontSizeName}px Zona Pro`;

			while (ctx.measureText(name).width > textWidth) {
				// console.log(fontSizeName);
				fontSizeName--;
				ctx.font = `700 ${fontSizeName}px Zona Pro`;
			}

			// ctx.fillText(nameLabel, canvas.width * .165, canvas.height * .57);
			// ctx.fillText(name, canvas.width * .165, (canvas.height * .57) + (
			// 	fontSizeName * 1.4));
			ctx.fillText(name, canvas.width * .165, canvas.height * .57);

			ctx.restore();

			// draw year
			ctx.save();

			ctx.font = `400 ${fontSizeYear}px Amarillo`;

			ctx.rotate(-6 * (Math.PI / 180));
			ctx.fillText(year, (canvas.width / 2) * .94, (canvas.height / 2) * 1.2);

			ctx.restore();
			return canvas
		}
		// DEMO
		// put imgs on they places
		const setImgs =
			/**
			 *
			 * @param {canvas} canvas
			 * @param {Object} set
			 * @returns {Promise<void>}
			 */
			async (canvas, set) => {
				const target = document.getElementById(set.name);
				if (!!target) {
					const imgs = [
						{
							name: "real",
							point: set.name + '_big'
						},
						{
							name: "thumbnail",
							point: set.name + '_small',
							sizeW: canvas.width / 4,
							sizeH: canvas.height / 4
						},
					]

					imgs.forEach(async (item)=>{
						const imgSrc = await new Promise((resolve) => {
							console.log(item.point, ' - start');
							if(item.name !== "real"){
								// canvas.width = item.sizeW;
								// canvas.height = item.sizeH;
								// canvas.toBlob((readyImg) => {
								// 		resolve(readyImg);
								// 	},
								// 	"image/png",
								// 	1
								// );
								const templCanvas = document.createElement('canvas'),
								templCtx = templCanvas.getContext('2d');
								templCanvas.width = item.sizeW;
								templCanvas.height = item.sizeH;
								templCtx.drawImage(canvas, 0, 0, templCanvas.width, templCanvas.height);
								templCanvas.toBlob((readyImg) => {
									canvas.remove();
									resolve(readyImg);
								},
								"image/png",
								1
							);

							} else {
								canvas.toBlob((readyImg) => {
									canvas.remove()
									resolve(readyImg);
									},
									"image/png",
									1
								);
							}

							console.log(item.point, ' - end');
						});

						const url = URL.createObjectURL(imgSrc);

						// console.log(url);
						if(item.name !== "real"){
							target.src = url;
						}else{
							target.dataset.mfpSrc = url;
						}

						localStorage.setItem(item.point, 'done')
						console.log(localStorage.getItem(item.point));

					})

					async function waitForLocalStorageItem() {
						while(localStorage.getItem(`${set.name}_big`) === undefined && localStorage.getItem(`${set.name}_small`) === undefined) {
						  await new Promise(resolve => setTimeout(resolve, 100));
						}
						return true
					}

					if(waitForLocalStorageItem()) {
						return true
					} else {
						return false
					}



					// const imgSrc = await new Promise((resolve) => {
					// 	console.log(set.name, ' - start');
					// 	canvas.toBlob((readyImg) => {
					// 			canvas.remove();
					// 			// readyImg.remove();
					// 			resolve(readyImg);
					// 		},
					// 		"image/png",
					// 		1
					// 	);
					// 	console.log(set.name, ' - end');
					// });
					// const url = URL.createObjectURL(await imgSrc);
					// target.src = url;




				}
			}

		// Live
		// put imgs on they places
		// const setImgs =
		// 	/**
		// 	 *
		// 	 * @param {canvas} canvas
		// 	 * @param {Object} set
		// 	 * @returns {Promise<void>}
		// 	 */
		// 	async (canvas, set) => {
		// 		const imgs = [
		// 			{
		// 				name: "real",
		// 				point: set.name + '_big'
		// 			},
		// 			{
		// 				name: "thumbnail",
		// 				point: set.name + '_small',
		// 				sizeW: canvas.width / 4,
		// 				sizeH: canvas.height / 4
		// 			},
		// 		]

		// 		imgs.forEach(async (item)=>{
		// 			const imgSrc = await new Promise((resolve) => {
		// 				console.log(item.point, ' - start');
		// 				if(item.name !== "real"){
		// 					canvas.width = item.sizeW;
		// 					canvas.height = item.sizeH;
		// 				}
		// 				canvas.toBlob((readyImg) => {
		// 						resolve(readyImg);
		// 					},
		// 					"image/png",
		// 					1
		// 				);
		// 				console.log(item.point, ' - end');
		// 			});

		// 			const url = URL.createObjectURL(await imgSrc);

		// 			localStorage.setItem(item.point, url)
		// 			console.log(localStorage.getItem(item.point));

		// 		})

		// 		async function waitForLocalStorageItem() {
		// 			while(localStorage.getItem(`${set.name}_big`) === undefined && localStorage.getItem(`${set.name}_small`) === undefined) {
		// 			  await new Promise(resolve => setTimeout(resolve, 100));
		// 			}
		// 			return true
		// 		}

		// 		if(waitForLocalStorageItem()) {
		// 			return true
		// 		} else {
		// 			return false
		// 		}

		// 	}




		// STEP 1 - get Img without bg from storage
		const getImgWithoutBg =
		/**
		 * @return {Promise<{imgWithoutBg: HTMLImageElement}>}
		 */
		async ()=> {
			const image = await new Promise((resolve) => {
				const imgToDraw = new Image();
				imgToDraw.onload = function () {
					resolve(imgToDraw)
				}
				imgToDraw.src = user.img;

			});
			return image;
		}


		// STEP 2 - get blob without bg and return blob with filters and mask
		const getFullHandledUserImg =
			/**
			 * @param {Object<number || string>} params
			 * @return {Promice<{readyUserImg: Blob}>}
			 */
			async (options = params) => {
				// console.log(options);
				const imgBitmap = await createImageBitmap(await getImgWithoutBg()),
					bnw = filter(imgBitmap, "grayscale(1)"),
					blur = filter(imgBitmap, "grayscale(1) invert(1) blur(55px)"),
					sketchImg = generateSketch(bnw, blur),
					maskedImg = await mask(sketchImg, options.mask);
				return maskedImg;

			}


		/*STEP 3 - making imgs with the bg and ready userPic and text */

		const imgsFabric = async () => {
			const maskedImg = await getFullHandledUserImg()

			const resultItems = await Promise.all(
				params.sets.map(async (set) => {

					const readyCanvas = await mix(maskedImg, set, `
					blur(${params.blur}px)
					brightness(${params.brightness}%)
					contrast(${params.contrast}%)
					drop-shadow(${params.dropShadowX}px ${params.dropShadowY}px ${params.dropShadowSpread}px rgba(${(params.dropShadowColor.r* 255).toFixed(0)},${(params.dropShadowColor.g* 255).toFixed(0)},${(params.dropShadowColor.b* 255).toFixed(0)},${params.dropShadowcolorAlpha}))
					hue-rotate(${params.hueRotate}deg)
					invert(${params.invert}%)
					opacity(${set.name === 'noir' ? 40 : params.opacity}%)
					saturate(${params.opacity}%)
					sepia(${params.sepia}%)
				`)


					if (set.name === 'noir') {
						const canvasWithText = await drawText(readyCanvas,
							set);
						const canvasWithBg = await drawCanvasWithBg(
							canvasWithText);
						return await setImgs(canvasWithBg, set)
					} else {
						const canvasWithText = await drawText(readyCanvas,
							set);
						return await setImgs(canvasWithText, set)

					}
				})
			)

			console.log(resultItems);
			let count = 0;
			resultItems.forEach((i)=>{
				if(i === true){
					count++
				}else{
					count--
				}
			})
			if(count === params.sets.length){
				console.log('set ok');
				return true;
			} else {
				console.log('set not ok');
				return false
			}

		}


		return await imgsFabric()
	}
