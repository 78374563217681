import interact from 'interactjs'


class ImgHandler {
    init() {
        console.log('interact is starting work with img');
        const imgField = document.getElementById('cropper'),
            imgWrapper = imgField.querySelector('.crop-img__inner'),
            scaleImg = document.querySelector('#cropImg');

        if (!!imgWrapper && !!scaleImg) {
            window.dragZone = interact(imgWrapper);
            const position = {
                x: 0,
                y: 0,
                scale: 1
            };


            const imgMoverAndResize = () => {
                imgWrapper.style.transform =
                    `translate(${position.x}px, ${position.y}px)`
            }

            imgMoverAndResize();


            dragZone
                .gesturable({
                    listeners: {
                        start(event) {

                        },
                        move(event) {
                            var currentScale = event.scale * position.scale
                            scaleImg.style.transform = 'scale(' + currentScale + ')'
                            imgMoverAndResize()
                        },
                        end(event) {
                            position.scale = position.scale * event.scale
                        }
                    }
                })
                .draggable({
                    inertia: true,
                    modifiers: [
                        // interact.modifiers.restrictRect({
                        // 	restriction: 'parent',
                        // 	endOnly: true
                        // })
                    ],
                    listeners: {
                        start(event) {},
                        move(event) {
                            position.x += event.dx
                            position.y += event.dy
                            imgMoverAndResize()
                        },
                        end(event) {

                        }
                    }
                })

                .on('doubletap', function (event) {
                    position.scale = position.scale === 1 ? 2 : 1;
                    scaleImg.style.transform = 'scale(' + position.scale + ')'
                    imgMoverAndResize();
                })
        }
    }
    stop() {
        console.log('interact stoped work with img');
        if(interact.isSet(document.querySelector('.crop-img__inner'))){
            interact(document.querySelector('.crop-img__inner')).unset()
        }
    }
}
const imgHandler = new ImgHandler();
export default imgHandler;
