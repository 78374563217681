import {
	isLocal
} from "./isLocal";
import { params } from './params';
import { makeDemo } from "./makeDemo";

export const prepareUserImg =
	/**
	 * @param {Promise<Object>}
	 * @return {Promise<{drawedImageWithBox: Blob;}>}
	 */
	// async (outerParams) => {
	async () => {
		const firstStep = document.getElementById('step1');

		firstStep.classList.add('is-sending');
		firstStep.dataset.sending = 0;


		const lang = document.documentElement.lang;
		const userImg = document.getElementById('readyUserImg');


		/* DEMO */
		const demoSrc = document.getElementById('demoSrc'),
			demoSrcData = document.getElementById('demoSrcData');




		// STEP 1 - get img from frame and return blob in useful size
		const getDataFromInput =
			/**
			 * @return {Promise<{readyImgInSize: Blob;}>}
			 */
			async () => {
				const userImg = document.getElementById('cropImg'),
					cropWrapper = document.getElementById('cropWrapper'),
					userImgX = userImg.getBoundingClientRect()['left'] - cropWrapper
					.getBoundingClientRect()['left'],
					userImgY = userImg.getBoundingClientRect()['top'] - cropWrapper
					.getBoundingClientRect()['top'],
					userImgScale = userImg.getBoundingClientRect().width / userImg
					.offsetWidth,
					userImgRealSizeW = userImg.width * userImgScale,
					userImgRealSizeH = userImg.height * userImgScale,
					userImgSrc = userImg.src,
					boxWidth = cropWrapper.offsetWidth,
					boxHeight = cropWrapper.offsetHeight,
					goodWidth = params.userImgSizeW,
					factor = Math.min((boxWidth / goodWidth), 1);

				// console.log(boxWidth);

				/** @type {HTMLCanvasElement} */
				const canvas = document.createElement("canvas");
				/** @type {CanvasRenderingContext2D} */
				const ctx = canvas.getContext("2d");
				canvas.width = boxWidth / factor;
				canvas.height = boxHeight / factor;


				const image = await new Promise((resolve) => {
					const readyImg = new Image();

					readyImg.onload = () => {
						ctx.drawImage(
							readyImg,
							userImgX / factor,
							userImgY / factor,
							userImgRealSizeW / factor,
							userImgRealSizeH / factor
						);


						canvas.toBlob((readyImgInSize) => {
								canvas.remove();
								readyImg.remove();
								// dataSend.originalImg = readyImgInSize;
								resolve(readyImgInSize);
							},
							"image/png",
							1
						);
						firstStep.dataset.sending++;

					};

					readyImg.src = userImgSrc;

				});
				return image;

			}

			if (isLocal() && showDemoOnFirstStep) {
				const 	readyBlob = await getDataFromInput();
				makeDemo(readyBlob, demoSrc, demoSrcData);
			}

		const readyBlob = await getDataFromInput();

		const url = URL.createObjectURL(readyBlob);


		userImg.src = url;


		if(!!readyBlob){
			return true
		}


	};
