// helper img to blob with current size
const sizes = {
	original: {
		w: 1999,
		h: 1187
	},
	// original: {
	// 	w: 5040,
	// 	h: 2993
	// },
	thumbnail: {
		w: 504,
		h: 299
	},
	imgUserOriginal: {
		w: 833,
		h: 999
	},
	imgUserThumbnail: {
		w: 333.2,
		h: 399.6
	}
}



export const imgToBlob =
/**
 * @param {HTMLImageElement} img
 * @param {string} size
 * @return {Promise<Blob>}
 */
async (img, size= 'original') => {

	/** @type {HTMLCanvasElement} */
	const canvas = document.createElement("canvas");
	/** @type {CanvasRenderingContext2D} */
	const ctx = canvas.getContext("2d");
	canvas.width = sizes[size].w;
	canvas.height = sizes[size].h;

	const image = await new Promise((resolve) => {
		const readyImg = new Image();
		readyImg.onload = () => {
			ctx.drawImage(
				readyImg,
				0,
				0,
				canvas.width,
				canvas.height
			);


			canvas.toBlob((readyImgInSize) => {
					canvas.remove();
					readyImg.remove();
					resolve(readyImgInSize);
				},
				"image/png",
				1
			);
		};

		readyImg.src = img.src;
	});
	return image;
}
